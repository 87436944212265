import React, { useState, useEffect } from "react";
import HeaderComponent from "../components/HeaderComponent";
import MenuComponent from "../components/MenuComponent";
import { useNavigate } from "react-router-dom";
import { IoMdHome } from "react-icons/io";
import { IoAddCircleOutline, IoSearch } from "react-icons/io5";
import { Modal, Button, Col, Row } from "react-bootstrap";
import { MdEdit } from "react-icons/md";
import Select from "react-select";
import { getProdutos, getSubprodutos } from "../services/produtosService";
import {
  getOperadores,
  getOperadorById,
  createOperador,
  updateOperador,
} from "../services/operadoresService";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export interface Operador {
  id: number;
  nome: string;
  email: string;
  telefone: string;
  nome_empresa: string;
  cidades: string;
  produtos: string;
  idUser: number;
}

interface Municipio {
  cod_municipio: number;
  nome_municipio: string;
  estado: string;
}

export interface Produto {
  id: number;
  descricao: string;
}

const OperadoresPage: React.FC = () => {
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );
  const [municipios, setMunicipios] = useState<Municipio[]>([]);
  const [operadores, setOperadores] = useState<Operador[]>([]);
  const [newOperador, setNewOperador] = useState<Operador>({
    id: 0,
    nome: "",
    email: "",
    telefone: "",
    nome_empresa: "",
    cidades: "",
    produtos: "",
    idUser: 0,
  });

  const [isEditing, setIsEditing] = useState(false);
  const [show, setShow] = useState(false);
  const [alerta, setAlerta] = useState("");
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const handleCidadeChange = (selectedOptions: any) => {
    const selectedCidadeIds = selectedOptions
      ? selectedOptions.map((option: any) => option.value)
      : [];

    const cidadesJson = selectedOptions
      ? JSON.stringify(selectedOptions)
      : "[]";

    setNewOperador((prevState) => ({
      ...prevState,
      cidades: cidadesJson,
      fazendas: selectedCidadeIds,
    }));

    setSelectedCidades(selectedOptions);
  };

  const [selectedCidades, setSelectedCidades] = useState<
    { value: string; label: string }[]
  >([]);

  const handleClose = () => setShow(false);

  const add = () => {
    setNewOperador({
      id: 0,
      nome: "",
      email: "",
      telefone: "",
      nome_empresa: "",
      cidades: "",
      produtos: "",
      idUser: 0,
    });

    setSelectedCidades([]);
    setSelectedProdutos([]);

    setIsEditing(false);
    setShow(true);
  };

  const [produtoOptions, setProdutoOptions] = useState<
    { value: string; label: string }[]
  >([]);

  const [selectedProdutos, setSelectedProdutos] = useState<
    { value: string; label: string }[]
  >([]);

  const handleProdutoChange = (selectedOptions: any) => {
    const produtosJson = selectedOptions
      ? JSON.stringify(selectedOptions)
      : "[]";

    setNewOperador((prevState) => ({
      ...prevState,
      produtos: produtosJson,
    }));

    setSelectedProdutos(selectedOptions);
  };

  const fetchProdutos = async () => {
    try {
      const response = await getProdutos();
      const formattedProdutos = response.map((produto: Produto) => ({
        value: String(produto.id),
        label: produto.descricao,
      }));
      setProdutoOptions(formattedProdutos);
    } catch (error) {
      console.error("Erro ao buscar produtos:", error);
    }
  };

  useEffect(() => {
    fetchProdutos();
  }, []);

  const edit = async (id: number) => {
    try {
      setSelectedCidades([]);
      setSelectedProdutos([]);

      const operador = await getOperadorById(id);

      const cidadesArray = operador.cidades
        ? typeof operador.cidades === "string"
          ? JSON.parse(operador.cidades)
          : operador.cidades
        : [];

      setSelectedCidades(cidadesArray);

      const produtosArray = operador.produtos
        ? typeof operador.produtos === "string"
          ? JSON.parse(operador.produtos)
          : operador.produtos
        : [];

      setSelectedProdutos(produtosArray);

      setNewOperador(operador);

      setIsEditing(true);
      setShow(true);
    } catch (error) {
      console.error("Erro ao buscar operador:", error);
    }
  };

  const notify = () => toast.info(alerta);

  useEffect(() => {
    if (alerta) {
      notify();
    }
  }, [alerta]);

  const fetchOperadores = async (search = "") => {
    try {
      const operadores = await getOperadores(null, search);
      setOperadores(operadores);
    } catch (error) {
      console.error("Erro ao buscar lista de operadores:", error);
    }
  };

  useEffect(() => {
    fetchOperadores();
  }, []);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;

    if (name === "telefone") {
      let cleanedValue = value.replace(/\D/g, ""); // Remove caracteres não numéricos

      let maskedValue = cleanedValue
        .replace(/(\d{2})(\d)/, "($1) $2") // Adiciona o parêntese para o DDD
        .replace(/(\d{5})(\d)/, "$1-$2") // Adiciona o hífen no número
        .replace(/(-\d{4})\d+?$/, "$1"); // Garante que apenas 4 dígitos sejam mantidos após o hífen

      setNewOperador({ ...newOperador, [name]: maskedValue });
      return;
    }

    setNewOperador({ ...newOperador, [name]: value });
  };

  const handleSubmit = async () => {
    setAlerta("");

    if (!newOperador.nome || !newOperador.email) {
      setAlerta("Os campos Nome e E-mail são obrigatórios.");
      return;
    }

    try {
      let result: any;

      if (isEditing) {
        result = await updateOperador(newOperador);
      } else {
        result = await createOperador(newOperador);
      }

      if (result.status === "error") {
        setAlerta(result.messages[0]);
      } else {
        fetchOperadores("");
        handleClose();
        setAlerta(
          isEditing
            ? "Operador atualizado com sucesso."
            : "Operador cadastrado com sucesso."
        );
      }
    } catch (error) {
      setAlerta("Não foi possível realizar esta operação.");
    }
  };

  const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (search.trim() === "" || search.trim().length >= 3) {
      fetchOperadores(search.trim());
    } else {
      setAlerta("O termo de pesquisa deve ter pelo menos 3 caracteres.");
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    if (event.target.value.trim() === "") {
      fetchOperadores("");
    }
  };

  useEffect(() => {
    const fetchMunicipios = async () => {
      const response = await fetch("/municipios.json");
      const data: Municipio[] = await response.json();

      const formattedData = data.map((municipio) => ({
        value: String(municipio.cod_municipio),
        label: `${municipio.nome_municipio} - ${municipio.estado}`,
      }));

      console.log(formattedData);

      setMunicipios(data);
      setOptions(formattedData);
    };

    fetchMunicipios();
  }, []);

  const style = document.createElement("style");
  style.innerHTML = `
      .modal-dialog{
        min-width: 90%;
      }
    `;
  document.head.appendChild(style);

  return (
    <>
      <HeaderComponent />
      <div className="page-content">
        <MenuComponent />
        <div className="content-wrapper">
          <div className="content-inner">
            <div className="page-header page-header-light">
              <div className="breadcrumb-line breadcrumb-line-light header-elements-lg-inline">
                <div className="d-flex">
                  <div className="breadcrumb">
                    <a
                      onClick={() => navigate("/")}
                      className="breadcrumb-item"
                    >
                      <IoMdHome /> &nbsp; Início
                    </a>
                    <span className="breadcrumb-item active">Operadores</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="content">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title d-flex float-left">
                    <Button className="bt-1-ico" variant="indigo" onClick={add}>
                      <IoAddCircleOutline className="m-0-auto fs-2em" />
                    </Button>
                    <span className="fs-1-5em pl-2">Operadores</span>
                  </h5>
                  <div className="float-right">
                    <form onSubmit={handleSearchSubmit}>
                      <div className="form-group form-group-feedback form-group-feedback-right mb-0 mt-1 min-w-400">
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Pesquisar por nome ou e-mail..."
                          value={search}
                          onChange={handleSearchChange}
                        />
                        <div className="form-control-feedback form-control-feedback-lg pt-0">
                          <button type="submit" className="btn btn-link p-0">
                            <IoSearch className="fs-1-7em" />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Nome</th>
                          <th>E-mail</th>
                          <th>Telefone / Whatsapp</th>
                          <th style={{ width: "80px" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {operadores.map((operador) => (
                          <tr key={operador.id}>
                            <td>{operador.id}</td>
                            <td>{operador.nome}</td>
                            <td>{operador.email}</td>
                            <td>{operador.telefone}</td>
                            <td>
                              <Button
                                variant="info"
                                size="sm"
                                className=""
                                onClick={() => edit(operador.id)}
                              >
                                <MdEdit />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            {isEditing ? "Editar Operador" : "Novo Operador"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={5}>
              <div className="form-group">
                <label>Nome:</label>
                <input
                  type="text"
                  className="form-control"
                  name="nome"
                  value={newOperador.nome}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col lg={5}>
              <div className="form-group">
                <label>E-mail:</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={newOperador.email}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col lg={2}>
              <div className="form-group">
                <label>Telefone / Whatsapp:</label>
                <input
                  type="text"
                  className="form-control"
                  name="telefone"
                  value={newOperador.telefone}
                  onChange={handleInputChange}
                />
              </div>
            </Col>

            <Col lg={12}>
              <div className="form-group">
                <label>Selecione uma ou várias cidades: </label>
                <Select
                  options={options}
                  isMulti
                  placeholder="Selecione"
                  name="fazendas"
                  value={selectedCidades}
                  onChange={(selectedOptions) =>
                    handleCidadeChange(selectedOptions)
                  }
                />
              </div>
            </Col>
            <Col lg={12}>
              <div className="form-group">
                <label>Selecione um ou vários produtos: </label>
                <Select
                  options={produtoOptions}
                  isMulti
                  placeholder="Selecione"
                  name="produtos"
                  value={selectedProdutos}
                  onChange={(selectedOptions) =>
                    handleProdutoChange(selectedOptions)
                  }
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {isEditing ? "Atualizar" : "Salvar"}
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
      />
    </>
  );
};

export default OperadoresPage;
